<!--
 * @Author: your name
 * @Date: 2021-04-27 22:02:21
 * @LastEditTime: 2021-04-30 10:43:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\components\address.vue
-->
<template>
  <div class="allmsgclass">
    <van-popup v-model="show" round position="bottom">
      <van-area
        ref="vanarea"
        :columns-num="msg"
        title="区域选择"
        @cancel="show = false"
        @confirm="onConfirm"
        :area-list="areaList"
      />
    </van-popup>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import { Tag } from 'vant';

let whetherToIncludeCityNodes = 1; //防止重复生成dom
const isHaveCityNodes = 1;
const noHaveCityNodes = 0;
const POPULARCITY = [
  {
    name: "北京市",
    value: "北京市/北京市",
  },
  {
    name: "上海市",
    value: "上海市/上海市",
  },
  {
    name: "深圳市",
    value: "广州省/深圳市",
  },
];

export default {
  data() {
    return {
      show: false,
      index: "",
      areaList: areaList, //地区数组
      msg: "3",
    };
  },
  mounted() {
    var that = this;
    window.clickCity = function (e) {
      let array = e.split("/");
      that.onConfirm([{name:array[0]},{name:array[1]}]);
    };
  },
  destroyed(){
    whetherToIncludeCityNodes = 1;
  },
  methods: {
    /**
     * 获取插入节点(热门城市)
     */
    getInsertNode() {
      return new Promise((res, rej) => {
        setTimeout(() => {
          if(this.$refs.vanarea.$el.childNodes[2]){
            res(this.$refs.vanarea.$el.childNodes[2]);
          }else{
            rej("");
          }
        });
      });
    },

    /**
     * 收集dom信息
     */

    /**
     * 创建热门城市
     */
    createNodeCity() {
      let div = document.createElement("div");
      div.className = "allcityclass";
      for (let i = 0; i < POPULARCITY.length; i++) {
        let dd = document.createElement("div");
        dd.innerHTML = POPULARCITY[i].name;
        dd.setAttribute("onclick", `clickCity(\'${POPULARCITY[i].value}\')`);
        div.appendChild(dd);
      }
      return div;
    },

    /**
     * 插入热门城市
     */
    addPopularCity() {
      //判断是否添加过热门城市（防止重复添加）
      if (whetherToIncludeCityNodes == isHaveCityNodes) {
        whetherToIncludeCityNodes = noHaveCityNodes;
        //添加热门城市
        this.getInsertNode().then((res) => {
          // console.log(this.$refs.vanarea.$el.childNodes[0].append("123"));
          res.parentNode.insertBefore(this.createNodeCity(), res);
        });
      }
    },

    addressmeth(msg, index) {
      if (msg) {
        this.msg = msg;
      }
      this.index = index;
      this.show = true;
      this.addPopularCity();
    },

    onConfirm(value) {
      this.show = false;
      this.$emit("addressreturn", value, this.index);
    },
  },
};
</script>
<style scoped>
.allmsgclass >>> .allcityclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0.3rem;
}
.allmsgclass >>> .allcityclass::before {
  content: "热门城市：";
  font-size: 0.3rem;
  font-weight: 500;
  display: block;
}
.allmsgclass >>> .allcityclass > div {
  font-size: 0.3rem;
  background-color: #ff5f19;
  color: #ffffff;
  padding: 0.1rem 0.2rem;
  border-radius: 0.1rem;
}
</style>
